/* eslint-disable react/display-name */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import UBCLogo from '../components/UBCLogo';
import BCITLogo from '../components/BCITLogo';

const degrees = [
    {
        logo: (props) => <UBCLogo {...props} />,
        school: 'University of British Columbia',
        name: 'Bachelor of Science - BS',
        program: 'Computer Science',
        interval: '2018 - 2021',
        location: 'Vancouver, BC',
    },
    {
        logo: (props) => <BCITLogo {...props} />,
        school: 'British Columbia Institute of Technology',
        name: 'Diploma',
        program: 'Full Stack Web Development',
        interval: '2020 - 2022',
        location: 'Vancouver, BC',
    },
    {
        logo: (props) => <UBCLogo {...props} />,
        school: 'University of British Columbia',
        name: 'Bachelor of Applied Science - BASc',
        program: 'Chemical & Biological Engineering',
        interval: '2014 - 2018',
        location: 'Vancouver, BC',
    },
];

export default degrees;
