/* eslint-disable react/display-name */
import React from 'react';

const projects = [
    {
        title: 'Minerva',
        url: 'https://github.com/MINERVA-MD',
        tags: ['featured', 'collab'],
        tech: ['vue', 'node', 'electron', 'tailwind', 'typescript'],
        summary: `Minerva is a simple, performant, and hackable Markdown editor that provides seamless GitHub integration and real-time collaboration.`,
        links: [
            {
                name: 'github',
                url: 'https://github.com/MINERVA-MD',
            },
            {
                name: 'website',
                url: 'https://minervaeditor.com/',
            },
        ],
        hero: () => {
            return (
                <img
                    className="w-full rounded shadow-xl ring-1 ring-black ring-opacity-5"
                    src="/images/minerva.jpg"
                    alt="Minerva"
                />
            );
        },
    },
    {
        title: 'Runik',
        url: 'https://runik.app/',
        tags: ['featured', 'collab'],
        tech: [
            'react',
            'node',
            'next',
            'tailwind',
            'amplify',
            's3',
            'mongo',
            'docker',
            'python',
            'typescript',
        ],
        summary: `Meet Runik, a web app that allows users to generate custom e-book dictionaries for their favourite fictional worlds. Stay fully immersed in your book while enjoying custom definitions of weapons, characters, places, and more.`,
        links: [
            {
                name: 'github',
                url: 'https://github.com/Runik-3',
            },
            {
                name: 'website',
                url: 'https://runik.app/',
            },
        ],
        hero: () => {
            return (
                <img
                    className="w-full rounded shadow-xl ring-1 ring-black ring-opacity-5"
                    src="/images/runik.jpg"
                    alt="Runik"
                />
            );
        },
    },
    {
        title: 'Abyssal',
        url: 'https://abyssal-website.herokuapp.com/',
        tags: ['featured', 'collab'],
        tech: [
            'unity',
            'webgl2',
            'csharp',
            'tailwind',
            'express',
            'mongo',
            's3',
        ],
        summary: `🎮 👻 Abyssal is the codename for our deep-sea, Metroidvania-style game. The player takes on the role of the amnesiac protagonist, Syba, to free a race of captured spirits and discover a lost history. To do so, the player will explore an ancient temple where they will solve puzzles to unlock rooms and new abilities. The player must find out what happened in the past to decide what will happen in the future.`,
        links: [
            {
                name: 'website',
                url: 'https://abyssal-website.herokuapp.com/',
            },
        ],
        hero: () => {
            return (
                <img
                    className="w-full rounded shadow-xl ring-1 ring-black ring-opacity-5"
                    src="/images/abyssal.jpg"
                    alt="Abyssal Gameplay (Boss)"
                />
            );
        },
    },

    {
        title: 'Twilight Drive-In',
        url: 'https://twilight-redesign.herokuapp.com/',
        tags: ['featured', 'collab'],
        tech: ['javascript', 'css', 'jquery', 'stripe'],
        summary: `A collaborative effort with three other full stack developers to propose a website redesign and rebranding for Twilight Drive-In theatre, in order to boost customer retention and conversion.`,
        links: [
            {
                name: 'github',
                url: 'https://github.com/jsam07/twilight-redesign',
            },
            {
                name: 'website',
                url: 'https://twilight-redesign.herokuapp.com/',
            },
        ],
        hero: () => {
            return (
                <img
                    className="w-full rounded shadow-xl ring-1 ring-black ring-opacity-5"
                    src="/images/twilight.jpg"
                    alt="Twilight Drive-In (Re-Design)"
                />
            );
        },
    },
    {
        title: 'Venture XPerience (VXP)',
        url: 'https://vxp-server-backup.vercel.app',
        tags: ['featured', 'collab'],
        tech: ['react', 'node', 'next', 'tailwind', 'amplify'],
        summary: `VentureXPerience (VXP) is Western Canada’s first-ever design-focused Hackathon. More than 300 designers from 20+ countries worldwide attended the inaugural event. `,
        links: [
            {
                name: 'website',
                url: 'https://vxp-server-backup.vercel.app',
            },
        ],
        hero: () => {
            return (
                <img
                    className="w-full rounded shadow-xl ring-1 ring-black ring-opacity-5"
                    src="/images/vxp.jpg"
                    alt="Venture XPerience (VXP)"
                />
            );
        },
    },
    {
        title: 'Dev House',
        url: 'https://github.com/jsam07/devHouse',
        tags: ['featured'],
        tech: ['typescript', 'express', 'prisma', 'passport'],
        summary: `Dev House is a twitter-like social networking platform for developers. Registered users can post, like, comment, and share posts with people in their network.`,
        links: [
            {
                name: 'github',
                url: 'https://github.com/jsam07/devHouse',
            },
        ],
        hero: () => {
            return (
                <img
                    className="w-full rounded shadow-xl ring-1 ring-black ring-opacity-5"
                    src="/images/dev.jpg"
                    alt="Dev House"
                />
            );
        },
    },

    {
        title: 'Express Documentation',
        url: 'https://megankuo.github.io/Express-User-Documentation/',
        tags: ['featured', 'collab', 'doc'],
        tech: ['markdown', 'javascript'],
        summary: `This documentation will guide you through setting up a Node.js project for building small to medium-scale RESTful APIs using Express.js. The aim is to provide a sustainable structure that simplifies complex projects into smaller, more reusable, and modular components. Focus is placed on best practices and various ways to optimize workflow when working collaboratively.`,
        links: [
            {
                name: 'github',
                url: 'https://github.com/megankuo/Express-User-Documentation',
            },
            {
                name: 'website',
                url: 'https://megankuo.github.io/Express-User-Documentation/',
            },
        ],
        hero: () => {
            return (
                <img
                    className="w-full rounded shadow-xl ring-1 ring-black ring-opacity-5"
                    src="/images/express.jpg"
                    alt="Express Documentation"
                />
            );
        },
    },
];

export default projects;
