import React from 'react';

import Degree from './Degree';
import degrees from '../content/degrees';

export default function Education() {
    return (
        <section
            id="education"
            className="hero pt-14 bg-white text-primary-blue max-w-4xl md:mx-auto"
        >
            <div className=" p-5">
                <h2 className="pb-[76px] education-header font-body font-extrabold text-4xl">
                    Education
                </h2>
                <div className="education-summaries">
                    {degrees.map((degree) => (
                        <Degree degree={degree} key={degree.name} />
                    ))}
                </div>
            </div>
        </section>
    );
}
