/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
import { send } from 'emailjs-com';
import { useFormik } from 'formik';
import { Transition, Dialog } from '@headlessui/react';
import React, { Fragment, useState, useEffect, useRef } from 'react';
import { XIcon } from '@heroicons/react/outline';
import {
    ChatAlt2Icon,
    CheckCircleIcon,
    ExclamationCircleIcon,
} from '@heroicons/react/solid';

import Notification from './Notification';
import HeroImage from '../images/hero-3-01.svg';
// import HeroImage from '../images/hero-bg.inline.svg';
// import HeroImage from '../images/hero-2-01.svg';
import MessageErrorNotification from './MessageErrorNotification';

const validate = (values) => {
    const errors = {};

    if (!values.firstName) {
        errors.firstName = 'First Name is required.';
    }

    if (!values.lastName) {
        errors.lastName = 'Last Name is required.';
    }

    if (!values.message) {
        errors.message = 'Message is required.';
    }

    if (!values.email) {
        errors.email = 'Email is required.';
    } else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
        errors.email = 'Invalid email address';
    }

    return errors;
};

export default function Hero() {
    const [isOpen, setIsOpen] = useState(false);
    const [isEmailSent, setIsEmailSent] = useState(false);
    const [isEmailError, setIsEmailError] = useState(false);

    const cancelButton = useRef(null);

    function openEmailNotification() {
        setIsEmailSent(true);
    }

    function openEmailErrorNotification() {
        setIsEmailError(true);
    }

    function setEmailErrorState(bool) {
        setIsEmailError(bool);
    }

    function closeModal() {
        setIsOpen(false);
    }

    function openModal() {
        setIsOpen(true);
    }

    function setEmailSentState(bool) {
        setIsEmailSent(bool);
    }

    const handleEmailSubmission = (values) => {
        send(
            'service_4oz67nv',
            'template_0yuet9m',
            {
                from_name: `${values.firstName}  ${values.lastName} - ${values.email}`,
                to_name: 'Jamie',
                message: values.message,
                email: values.email,
            },
            'user_PXmWqJLfquW9Rbw3Q5iI9'
        )
            .then(() => {
                closeModal();
                openEmailNotification();
            })
            .catch((err) => {
                closeModal();
                openEmailErrorNotification();
            });
    };

    const formik = useFormik({
        initialValues: {
            firstName: '',

            lastName: '',

            email: '',

            message: '',

            subject: '',
        },

        validate,

        onSubmit: (values) => handleEmailSubmission(values),
    });

    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            formik.resetForm();
            document.body.style.overflow = 'unset';
        }
    }, [isOpen]);

    return (
        <section id="hero" className="hero text-white pt-12 md:pt-14">
            <div className="hero-container flex flex-col items-center justify-center ">
                <h1 className="w-full h-auto max-w-6xl">
                    {/* <img
                        src="images/hero-2-01.svg"
                        alt="Jamie Samuel - Full-Stack Web Developer"
                    /> */}
                    <HeroImage alt="Jamie Samuel - Full-Stack Web Developer" />
                </h1>
                <div className="resume-cta w-full max-w-xl flex flex-col">
                    <button
                        type="button"
                        onClick={openModal}
                        className="inline-flex items-center justify-center font-medium px-16 py-2 m-8 border-2 border-transparent text-lg rounded shadow-sm text-white bg-btn-red  transition duration-500 ease-in-out hover:translate-x-1 hover:border-white"
                    >
                        <span>Get In Touch</span>
                    </button>
                </div>
            </div>
            <Transition.Root show={isOpen} as={Fragment}>
                <Dialog
                    as="div"
                    auto-reopen="true"
                    className="fixed z-50 inset-0 overflow-y-auto"
                    onClose={closeModal}
                >
                    <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                        </Transition.Child>

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span
                            className="hidden sm:inline-block sm:align-middle sm:h-screen"
                            aria-hidden="true"
                        >
                            &#8203;
                        </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                                <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                                    <button
                                        type="button"
                                        className="bg-white rounded-md text-gray-400 hover:text-gray-500"
                                        onClick={closeModal}
                                    >
                                        <span className="sr-only">Close</span>
                                        <XIcon
                                            className="h-6 w-6"
                                            aria-hidden="true"
                                        />
                                    </button>
                                </div>
                                <div className="sm:flex sm:items-start">
                                    <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-gradient-to-r from-[#E70183] to-[#552D90] sm:mx-0 sm:h-10 sm:w-10">
                                        <ChatAlt2Icon
                                            className="h-6 w-6 text-white"
                                            aria-hidden="true"
                                        />
                                    </div>
                                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                        <Dialog.Title
                                            as="h3"
                                            className="text-2xl font-bold leading-6 text-gray-900 sm:pt-1"
                                        >
                                            Contact Me
                                        </Dialog.Title>
                                        <div className="mt-2">
                                            <p className="text-sm text-gray-500">
                                                If you&apos;re interested in
                                                working together or have any
                                                feedback, I&apos;d love to hear
                                                from you!
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <form onSubmit={formik.handleSubmit}>
                                    <div className="px-4 py-5 bg-white sm:p-6">
                                        <div className="grid grid-cols-6 gap-6">
                                            <div className="col-span-6 sm:col-span-3">
                                                <label
                                                    htmlFor="firstName"
                                                    className="block text-sm font-medium text-gray-700"
                                                >
                                                    First Name
                                                    <div className="mt-1 relative rounded-md shadow-sm">
                                                        <input
                                                            type="text"
                                                            name="firstName"
                                                            id="firstName"
                                                            autoComplete="given-name"
                                                            // placeholder="Jane"
                                                            onChange={
                                                                formik.handleChange
                                                            }
                                                            value={
                                                                formik.values
                                                                    .firstName
                                                            }
                                                            className={`${
                                                                formik.errors
                                                                    .firstName
                                                                    ? 'border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500'
                                                                    : formik
                                                                          .values
                                                                          .firstName
                                                                    ? 'border-green-300 text-green-900 placeholder-green-300 focus:outline-none focus:ring-green-500 focus:border-green-500'
                                                                    : 'focus:ring-project-link focus:border-project-link'
                                                            } placeholder-gray-500 placeholder-opacity-50  block w-full shadow-sm sm:text-sm border-gray-300 rounded-md`}
                                                        />
                                                        {formik.errors
                                                            .firstName ? (
                                                            <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                                                <ExclamationCircleIcon
                                                                    className="h-5 w-5 text-red-500"
                                                                    aria-hidden="true"
                                                                />
                                                            </div>
                                                        ) : formik.values
                                                              .firstName ? (
                                                            <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                                                <CheckCircleIcon
                                                                    className="h-5 w-5 text-green-500"
                                                                    aria-hidden="true"
                                                                />
                                                            </div>
                                                        ) : (
                                                            ''
                                                        )}
                                                    </div>
                                                </label>
                                                {formik.errors.firstName && (
                                                    <p
                                                        className="mt-2 text-sm text-red-600"
                                                        id="firstName-error"
                                                    >
                                                        {
                                                            formik.errors
                                                                .firstName
                                                        }
                                                    </p>
                                                )}
                                            </div>

                                            <div className="col-span-6 sm:col-span-3">
                                                <label
                                                    htmlFor="lastName"
                                                    className="block text-sm font-medium text-gray-700"
                                                >
                                                    Last Name
                                                    <div className="mt-1 relative rounded-md shadow-sm">
                                                        <input
                                                            type="text"
                                                            name="lastName"
                                                            id="lastName"
                                                            autoComplete="family-name"
                                                            // placeholder="Doe"
                                                            onChange={
                                                                formik.handleChange
                                                            }
                                                            value={
                                                                formik.values
                                                                    .lastName
                                                            }
                                                            className={`${
                                                                formik.errors
                                                                    .lastName
                                                                    ? 'border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500'
                                                                    : formik
                                                                          .values
                                                                          .lastName
                                                                    ? 'border-green-300 text-green-900 placeholder-green-300 focus:outline-none focus:ring-green-500 focus:border-green-500'
                                                                    : 'focus:ring-project-link focus:border-project-link'
                                                            } placeholder-gray-500 placeholder-opacity-50  block w-full shadow-sm sm:text-sm border-gray-300 rounded-md`}
                                                        />
                                                        {formik.errors
                                                            .lastName ? (
                                                            <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                                                <ExclamationCircleIcon
                                                                    className="h-5 w-5 text-red-500"
                                                                    aria-hidden="true"
                                                                />
                                                            </div>
                                                        ) : formik.values
                                                              .lastName ? (
                                                            <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                                                <CheckCircleIcon
                                                                    className="h-5 w-5 text-green-500"
                                                                    aria-hidden="true"
                                                                />
                                                            </div>
                                                        ) : (
                                                            ''
                                                        )}
                                                    </div>
                                                </label>
                                                {formik.errors.lastName && (
                                                    <p
                                                        className="mt-2 text-sm text-red-600"
                                                        id="lastName-error"
                                                    >
                                                        {formik.errors.lastName}
                                                    </p>
                                                )}
                                            </div>

                                            <div className="col-span-6 sm:col-span-6">
                                                <label
                                                    htmlFor="email"
                                                    className="block text-sm font-medium text-gray-700"
                                                >
                                                    Email Address
                                                    <div className="mt-1 relative rounded-md shadow-sm">
                                                        <input
                                                            type="email"
                                                            name="email"
                                                            id="email"
                                                            autoComplete="email"
                                                            // placeholder="test@gmail.com"
                                                            onChange={
                                                                formik.handleChange
                                                            }
                                                            value={
                                                                formik.values
                                                                    .email
                                                            }
                                                            className={`${
                                                                formik.errors
                                                                    .email
                                                                    ? 'border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500'
                                                                    : formik
                                                                          .values
                                                                          .email
                                                                    ? 'border-green-300 text-green-900 placeholder-green-300 focus:outline-none focus:ring-green-500 focus:border-green-500'
                                                                    : 'focus:ring-project-link focus:border-project-link'
                                                            } placeholder-gray-500 placeholder-opacity-50  block w-full shadow-sm sm:text-sm border-gray-300 rounded-md`}
                                                        />
                                                        {formik.errors.email ? (
                                                            <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                                                <ExclamationCircleIcon
                                                                    className="h-5 w-5 text-red-500"
                                                                    aria-hidden="true"
                                                                />
                                                            </div>
                                                        ) : formik.values
                                                              .email ? (
                                                            <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                                                <CheckCircleIcon
                                                                    className="h-5 w-5 text-green-500"
                                                                    aria-hidden="true"
                                                                />
                                                            </div>
                                                        ) : (
                                                            ''
                                                        )}
                                                    </div>
                                                </label>
                                                {formik.errors.email && (
                                                    <p
                                                        className="mt-2 text-sm text-red-600"
                                                        id="lastName-error"
                                                    >
                                                        {formik.errors.email}
                                                    </p>
                                                )}
                                            </div>

                                            <div className="col-span-6 sm:col-span-6">
                                                <label
                                                    htmlFor="subject"
                                                    className="block text-sm font-medium text-gray-700"
                                                >
                                                    Subject
                                                    <div className="mt-1 relative rounded-md shadow-sm">
                                                        <input
                                                            type="text"
                                                            name="subject"
                                                            id="subject"
                                                            autoComplete="family-name"
                                                            // placeholder="Subject"
                                                            onChange={
                                                                formik.handleChange
                                                            }
                                                            className="block w-full mt-1 placeholder-gray-500 placeholder-opacity-5 focus:ring-project-link focus:border-project-link shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                        />
                                                    </div>
                                                </label>
                                            </div>
                                            <div className="col-span-6 sm:col-span-6">
                                                <label
                                                    htmlFor="message"
                                                    className="block text-sm font-medium text-gray-700"
                                                >
                                                    Message
                                                    <div className="mt-1 relative rounded-md shadow-sm">
                                                        <div className="mt-1">
                                                            <textarea
                                                                id="message"
                                                                name="message"
                                                                rows={8}
                                                                // defaultValue=""
                                                                onChange={
                                                                    formik.handleChange
                                                                }
                                                                value={
                                                                    formik
                                                                        .values
                                                                        .message
                                                                }
                                                                // placeholder="Type message here"
                                                                className={`${
                                                                    formik
                                                                        .errors
                                                                        .message
                                                                        ? 'border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500'
                                                                        : formik
                                                                              .values
                                                                              .message
                                                                        ? 'border-green-300 text-green-900 placeholder-green-300 focus:outline-none focus:ring-green-500 focus:border-green-500'
                                                                        : 'focus:ring-project-link focus:border-project-link'
                                                                } placeholder-gray-500 placeholder-opacity-50  block w-full shadow-sm sm:text-sm border-gray-300 rounded-md`}
                                                            />
                                                        </div>
                                                        {formik.errors
                                                            .message ? (
                                                            <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                                                <ExclamationCircleIcon
                                                                    className="h-5 w-5 text-red-500"
                                                                    aria-hidden="true"
                                                                />
                                                            </div>
                                                        ) : formik.values
                                                              .message ? (
                                                            <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                                                <CheckCircleIcon
                                                                    className="h-5 w-5 text-green-500"
                                                                    aria-hidden="true"
                                                                />
                                                            </div>
                                                        ) : (
                                                            ''
                                                        )}
                                                    </div>
                                                </label>
                                                {formik.errors.message && (
                                                    <p
                                                        className="mt-2 text-sm text-red-600"
                                                        id="lastName-error"
                                                    >
                                                        {formik.errors.message}
                                                    </p>
                                                )}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                                        <button
                                            type="submit"
                                            className="w-full inline-flex items-center justify-center rounded-md  shadow-sm px-4 py-2 bg-gradient-to-r from-[#E70183] to-[#552D90] text-base font-medium text-white hover:bg-red-700 sm:ml-3 sm:w-auto sm:text-sm"
                                        >
                                            Send Message
                                        </button>
                                        <button
                                            type="button"
                                            className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
                                            onClick={() => {
                                                formik.resetForm();
                                                closeModal();
                                            }}
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition.Root>
            {isEmailSent && <Notification setState={setEmailSentState} />}
            {isEmailError && (
                <MessageErrorNotification setState={setEmailErrorState} />
            )}
        </section>
    );
}
