import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';

import NavBar from './NavBar';
import Footer from './Footer';
import MainContent from './MainContent';

const siteUrl = process.env.SITE_URL || `https://www.jamiesamuel.com`;

export default function Portfolio() {
    return (
        <>
            <Helmet htmlAttributes={{ lang: 'en' }} />
            <Helmet>
                <base href={siteUrl} />
                <meta name="referrer" content="origin" />
                <link rel="canonical" href={siteUrl} />
                <title>
                    Jamie Samuel - Full-Stack Web Developer | Portfolio
                </title>

                <meta
                    name="description"
                    content="Jamie Samuel is a self-driven, charismatic, and detail-oriented Full-Stack Web Developer with a Chemical & Biological Engineering background, based in Vancouver, British Columbia. He loves collaborating cross-functionally to deliver innovative and engaging solutions across brand design, content creation, and web development."
                />
                <meta
                    name="keyword"
                    content="portfolio, javascript, typescript, react, python, developer, engineer, vancouver, british columbia"
                />

                <meta property="og:locale" content="en_CA" />
                <meta property="og:type" content="website" />
                <meta
                    property="og:site_name"
                    content="Jamie Samuel - Full-Stack Web Developer | Portfolio"
                />
                <meta
                    property="og:url"
                    content="https://www.jamiesamuel.com/"
                />
                <meta
                    property="og:title"
                    content="Jamie Samuel - Full-Stack Web Developer | Portfolio"
                />
                <meta
                    property="og:description"
                    content="Jamie Samuel is a self-driven, charismatic, and detail-oriented Full-Stack Web Developer with a Chemical & Biological Engineering background, based in Vancouver, British Columbia. He loves collaborating cross-functionally to deliver innovative and engaging solutions across brand design, content creation, and web development."
                />

                <meta
                    property="og:image"
                    content="https://www.jamiesamuel.com/images/hero.jpeg"
                />
                <meta
                    property="og:image:secure_url"
                    content="https://www.jamiesamuel.com/images/hero.jpeg"
                />
                <meta property="og:image:type" content="image/jpeg" />
                <meta property="og:image:width" content="1442.56" />
                <meta property="og:image:height" content="1000.8" />
                <meta
                    property="og:image:alt"
                    content="Jamie Samuel, Full-Stack Web Developer"
                />

                <meta
                    name="twitter:image"
                    content="https://www.jamiesamuel.com/images/hero.jpeg"
                />
                <meta name="twitter:image:width" content="1442.56" />
                <meta name="twitter:image:height" content="1000.8" />
                <meta
                    name="twitter:image:alt"
                    content="Jamie Samuel, Full-Stack Web Developer"
                />

                <meta name="twitter:card" content="summary_large_image" />
                <meta
                    name="twitter:title"
                    content="Jamie Samuel - Full-Stack Web Developer | Portfolio"
                />
                <meta
                    name="twitter:description"
                    content="Jamie Samuel is a self-driven, charismatic, and detail-oriented Full-Stack Web Developer with a Chemical & Biological Engineering background, based in Vancouver, British Columbia. He loves collaborating cross-functionally to deliver innovative and engaging solutions across brand design, content creation, and web development."
                />
                <script type="application/ld+json">
                    {`

                        {
                            "@context": "https://schema.org/",
                            "@type": "WebSite",
                            "name": "Jamie Samuel - Full-Stack Web Developer | Portfolio",
                            "author": {
                                "@type": "Person",
                                "@id": "#jamiesamuel"
                            },
                            "description": "Jamie Samuel is a self-driven, charismatic, and detail-oriented Full-Stack Web Developer with a Chemical & Biological Engineering background, based in Vancouver, British Columbia. He loves collaborating cross-functionally to deliver innovative and engaging solutions across brand design, content creation, and web development.",
                            "image": "https://www.jamiesamuel.com/images/hero.jpeg",
                            "url": "https://www.jamiesamuel.com/",
                            "keywords": "portfolio, javascript, typescript, react, python, developer, engineer, vancouver, british columbia"
                        }
                        
                    `}
                </script>
                <script type="application/ld+json">
                    {`
                        {
                            "@context": "https://schema.org/",
                            "@type": "Person",
                            "@id": "#jamiesamuel",
                            "name": "Jamie Samuel",
                            "email": "jsam07@hotmail.com",
                            "url": "https://www.jamiesamuel.com/"
                        }
                    `}
                </script>
            </Helmet>
            <Helmet>
                <body className="portfolio-container font-body text-gray-600 min-h-screen" />
            </Helmet>
            <div className="main-container bg-primary-blue">
                <header className="header-container sticky top-0 z-40">
                    <NavBar />
                </header>
                <main className="mb-auto flex-1 bg-white ">
                    <MainContent />
                </main>
                <footer>
                    <Footer />
                </footer>
            </div>
        </>
    );
}
