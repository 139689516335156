/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
// import LogoIcon from '../images/logo.inline.svg';

function LogoIcon(props) {
    return (
        <>
            <svg
                id="prefix__art"
                xmlns="http://www.w3.org/2000/svg"
                width={338.948}
                height={292.365}
                viewBox="0 0 338.948 292.365"
                {...props}
            >
                <defs>
                    <radialGradient
                        id="prefix__radial-gradient"
                        cx={-863.87}
                        cy={2731.33}
                        r={112.513}
                        gradientTransform="scale(-1 1) rotate(-67.387 -2603.34 939.958)"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0} stopColor="#8bc53f" />
                        <stop offset={0.047} stopColor="#6bbe54" />
                        <stop offset={0.114} stopColor="#45b56e" />
                        <stop offset={0.18} stopColor="#27af82" />
                        <stop offset={0.245} stopColor="#11aa90" />
                        <stop offset={0.308} stopColor="#04a799" />
                        <stop offset={0.367} stopColor="#00a69c" />
                        <stop offset={0.469} stopColor="#0996a6" />
                        <stop offset={0.634} stopColor="#1b75bb" />
                        <stop offset={0.758} stopColor="#3160ae" />
                        <stop offset={1} stopColor="#652d90" />
                    </radialGradient>
                    <radialGradient
                        id="prefix__radial-gradient-2"
                        cx={-965.167}
                        cy={3383.582}
                        r={172.159}
                        gradientTransform="matrix(-.3014 -.9535 -.8775 .2774 2675.799 -1711.14)"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0} stopColor="#f6921e" />
                        <stop offset={0.034} stopColor="#f5872a" />
                        <stop offset={0.183} stopColor="#f15a59" />
                        <stop offset={0.297} stopColor="#ee3d76" />
                        <stop offset={0.362} stopColor="#ed3381" />
                        <stop offset={0.476} stopColor="#db2e80" />
                        <stop offset={0.63} stopColor="#bd267e" />
                        <stop offset={0.838} stopColor="#981565" />
                        <stop offset={0.995} stopColor="#810b55" />
                    </radialGradient>
                    <radialGradient
                        id="prefix__radial-gradient-3"
                        cx={-1010.29}
                        cy={3310.845}
                        r={193.263}
                        gradientTransform="scale(-1 1) rotate(-72.458 -2619.144 1053.91)"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.011} stopColor="#fbc7ac" />
                        <stop offset={0.243} stopColor="#f6921e" />
                        <stop offset={0.272} stopColor="#f5872a" />
                        <stop offset={0.397} stopColor="#f15a59" />
                        <stop offset={0.493} stopColor="#ee3d76" />
                        <stop offset={0.547} stopColor="#ed3381" />
                        <stop offset={0.661} stopColor="#db2e80" />
                        <stop offset={0.815} stopColor="#bd267e" />
                        <stop offset={0.917} stopColor="#981565" />
                        <stop offset={0.995} stopColor="#810b55" />
                    </radialGradient>
                    <radialGradient
                        id="prefix__radial-gradient-4"
                        cx={-994.919}
                        cy={2809.528}
                        r={188.368}
                        gradientTransform="scale(-1 1) rotate(-67.387 -2603.34 939.958)"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0} stopColor="#8bc53f" />
                        <stop offset={0.128} stopColor="#56b963" />
                        <stop offset={0.29} stopColor="#18ab8c" />
                        <stop offset={0.367} stopColor="#00a69c" />
                        <stop offset={0.481} stopColor="#0996a6" />
                        <stop offset={0.667} stopColor="#1b75bb" />
                        <stop offset={1} stopColor="#652d90" />
                    </radialGradient>
                    <linearGradient
                        id="prefix__linear-gradient"
                        x1={-1196.093}
                        y1={3170.227}
                        x2={-984.976}
                        y2={3170.227}
                        gradientTransform="matrix(-.3014 -.9535 -.8775 .2774 2675.799 -1711.14)"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0} stopColor="#f9ec31" />
                        <stop offset={0.11} stopColor="#f8c92a" />
                        <stop offset={0.228} stopColor="#f7ab23" />
                        <stop offset={0.329} stopColor="#f6991f" />
                        <stop offset={0.4} stopColor="#f6921e" />
                        <stop offset={0.527} stopColor="#f4833d" />
                        <stop offset={0.688} stopColor="#f27160" />
                        <stop offset={0.826} stopColor="#ee3876" />
                        <stop offset={0.941} stopColor="#ec1085" />
                        <stop offset={1} stopColor="#eb008b" />
                    </linearGradient>
                    <style>{'.prefix__cls-1{fill:none}'}</style>
                </defs>
                <path
                    id="prefix__path105"
                    className="prefix__cls-1"
                    d="M173.613 144.742a130.868 130.868 0 00.802-15.76 2.216 2.216 0 000-.303c-.04 4.585-.243 9.975-.802 16.063z"
                />
                <path
                    id="prefix__path107"
                    d="M173.226 66.861c11.985 58.324 68.86 70.552 85.478 80.673a1.302 1.302 0 01.195.125c-17.889-12.76-24.929-26.125-22.789-43.213 2.902-22.972 33.534-56.971 21.554-88.264C252.988 3.98 236.382-2.89 217.047 1.169c-12.29 5.319-52.61 22.946-43.82 65.692z"
                    fill="url(#prefix__radial-gradient)"
                />
                <path
                    id="prefix__path109"
                    className="prefix__cls-1"
                    d="M173.705 112.859s.588 7.953.71 15.82a126.728 126.728 0 00-.71-15.82z"
                />
                <path
                    id="prefix__path111"
                    d="M50.872 269.33c31.306 8.465 67.196-10.855 67.655-11.104 3.77-3.218 7.336-6.516 10.691-9.76s20.687-21.71 31.458-49.09a199.795 199.795 0 0012.937-54.633c-.013.014-51.658-7.027-97.208 2.66-3.087 1.057-39.838 14.402-56.216 46.798-16.22 32.093 2.574 67.525 30.683 75.129z"
                    fill="url(#prefix__radial-gradient-2)"
                />
                <path
                    id="prefix__path113"
                    d="M35.11 163.466c-40.31 26.867-51.688 90.464-3.126 115.243 32.494 16.569 63.216-.48 86.543-20.483-.46.249-36.35 19.569-67.655 11.104-28.109-7.604-46.904-43.036-30.683-75.13 16.379-32.395 53.129-45.74 56.216-46.798-15.145 3.211-29.618 8.283-41.295 16.064z"
                    fill="url(#prefix__radial-gradient-3)"
                />
                <path
                    id="prefix__path115"
                    d="M174.415 128.679a2.216 2.216 0 010 .302 130.868 130.868 0 01-.802 15.761 199.795 199.795 0 01-12.937 54.634 74.896 74.896 0 01-1.366 3.212c-11.006 25.717-29.92 45.694-30.078 45.865 5.635-5.463 10.678-10.784 15-15.328 28.24-29.829 52.658-17.298 76.814 9.003 28.346 30.873 79.807 73.765 109.071 34.512 17.507-23.484 6.538-54.18-11.625-79.004-.456-.46-19.532-29.909-58.947-44.776a128.475 128.475 0 00-17.8-5.274c-48.101-10.58-68.04-34.727-68.04-34.727a126.725 126.725 0 01.71 15.82z"
                    fill="url(#prefix__linear-gradient)"
                />
                <path
                    id="prefix__path117"
                    d="M173.948 29.631c-32.475 40.533-.243 83.228-.243 83.228s16.34 24.751 66.358 37.84c52.777 13.811 77.916 46.424 78.429 46.937-13.305-18.191-30.475-33.231-43.16-39.982a148.833 148.833 0 01-16.433-9.995 1.302 1.302 0 00-.195-.124c-16.617-10.122-79.311-34.959-82.227-85.37-2.285-39.285 28.28-55.677 40.57-60.996-14.104 2.955-29.668 11.716-43.099 28.462z"
                    fill="url(#prefix__radial-gradient-4)"
                />
            </svg>
        </>
    );
}

export default function Logo() {
    return (
        <>
            <a href="/" aria-label="Home">
                {/* <img
                    className="block h-6 w-auto"
                    src="/images/logo.svg"
                    alt="Logo"
                /> */}
                <LogoIcon className="block h-6 sm:h-7 w-auto" alt="Logo" />
            </a>
        </>
    );
}
