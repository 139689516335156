/* eslint-disable react/prop-types */
import React from 'react';
import { useInView } from 'react-intersection-observer';

export default function Degree({ degree }) {
    const { ref, inView } = useInView({ threshold: 0, triggerOnce: true });
    const Logo = degree.logo;
    return (
        <>
            <div
                ref={ref}
                className={`education-section mb-8 p-4 flex flex-row bg-secondary-gray rounded-lg fade-in-section  ${
                    inView ? 'is-visible' : ''
                }`}
            >
                <div className="flex-none school-logo mr-3 w-10 h-10">
                    <Logo />
                </div>
                <div className="degree-summary">
                    <div className="school-name font-bold ">
                        {degree.school}
                    </div>
                    <div className="school-details leading-relaxed">
                        <div className="degree-name">{degree.name}</div>
                        <div className="degree-program">{degree.program}</div>
                        <div className="degree-interval">{degree.interval}</div>
                        <div className="degree-location">{degree.location}</div>
                    </div>
                </div>
            </div>
        </>
    );
}
