/* eslint-disable react/prop-types */
import React from 'react';
import { useInView } from 'react-intersection-observer';

import tags from '../content/tags';
import links from '../content/links';

export default function Project({ project }) {
    const { ref, inView } = useInView({ threshold: 0, triggerOnce: true });
    const Hero = project.hero;

    return (
        <>
            <div
                ref={ref}
                className={`project-container flex flex-col mb-12 p-4 bg-secondary-gray transition duration-100 ease-in-out rounded-lg shadow-sm hover:shadow-lg fade-in-section  ${
                    inView ? 'is-visible' : ''
                }`}
            >
                <div className="title-links mb-3 flex flex-row items-start justify-between">
                    <div className="project-title font-bold text-project-link-pale text-3xl">
                        <a
                            href={project.url}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-project-link-pale transition duration-300 ease-in-out hover:text-project-link"
                        >
                            {project.title}
                        </a>
                    </div>
                    <div className="flex flex-row items-center ml-auto project-links">
                        {project.links.map((link) => {
                            const URL = links[link.name];
                            return <URL url={link.url} key={link.name} />;
                        })}
                    </div>
                </div>
                {/* <div className="tags flex flex-row flex-wrap">
                    {project.tags.map((tag) => {
                        const Tag = tags[tag];
                        return <Tag key={tag} />;
                    })}
                </div> */}
                <div className="flex items-center justify-center project-hero m-6">
                    <a
                        href={project.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        aria-label={`${project.title} Thumbnail`}
                    >
                        <Hero />
                    </a>
                </div>

                <div className="project-stack flex flex-row flex-wrap items-center justify-center mb-4">
                    {project.tech.map((tag) => {
                        const Tag = tags[tag];
                        return <Tag key={tag} />;
                    })}
                </div>
                <div className="project-summary mx-6 leading-relaxed">
                    {project.summary}
                </div>
            </div>
        </>
    );
}
