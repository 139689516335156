/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */

import React from 'react';

export default function VXPLogo(props) {
    return (
        <a
            href="https://www.linkedin.com/company/vxpdesign/"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="VXP Logo"
        >
            <svg
                width={40}
                height={40}
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                {...props}
            >
                <path d="M40 0H0v40h40V0z" fill="url(#prefix__paint0_linear)" />
                <path fill="#FAFAFA" d="M31.867 23.989h2.067v2.067h-2.067z" />
                <path
                    fill="url(#prefix__paint1_linear)"
                    d="M33.933 23.989H36v2.067h-2.067z"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M26.695 25.875c.08.08.178.12.292.12h1.628c.125 0 .222-.04.291-.12.08-.08.12-.177.12-.291V21.83c0-.142.115-.257.257-.257h2.296c1.382 0 2.462-.314 3.239-.943.788-.64 1.182-1.582 1.182-2.827 0-1.245-.394-2.188-1.182-2.827-.788-.651-1.868-.977-3.239-.977h-2.33a.257.257 0 00-.257.257v1.388a.257.257 0 01-.257.257h-1.902a.257.257 0 00-.257.257v9.425c0 .114.04.21.12.29zm4.799-6.203c.662 0 1.17-.154 1.525-.462.354-.32.531-.788.531-1.405 0-.594-.171-1.057-.514-1.388-.343-.343-.857-.514-1.542-.514h-2.245a.257.257 0 00-.257.257v3.255c0 .142.115.258.257.258h2.245z"
                    fill="#FAFAFA"
                />
                <path
                    d="M9.352 25.995c-.33 0-.548-.16-.65-.48l-3.668-11L5 14.36a.349.349 0 01.36-.36h1.508c.148 0 .262.04.342.12a.523.523 0 01.189.257l2.896 8.825 2.896-8.825a.512.512 0 01.171-.257.526.526 0 01.36-.12h1.508c.091 0 .171.034.24.103.08.068.12.154.12.257l-.035.154-3.667 11.001c-.102.32-.32.48-.65.48H9.351zM15.95 25.995a.349.349 0 01-.36-.36c0-.069.017-.137.051-.206l3.838-5.552-3.598-5.311a.392.392 0 01-.052-.206.349.349 0 01.36-.36h1.748c.206 0 .383.108.531.326l2.52 3.7 2.535-3.7c.149-.218.326-.326.532-.326h1.662a.349.349 0 01.36.36.32.32 0 01-.07.206l-3.58 5.311 3.838 5.552c.046.092.069.16.069.206 0 .103-.04.188-.12.257a.329.329 0 01-.24.103h-1.817c-.217 0-.388-.103-.514-.309l-2.741-3.872-2.69 3.872c-.15.206-.326.309-.532.309h-1.73z"
                    fill="#FAFAFA"
                />
                <defs>
                    <linearGradient
                        id="prefix__paint0_linear"
                        x1={0}
                        y1={0}
                        x2={47.748}
                        y2={25.48}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#7672CB" />
                        <stop offset={0.745} stopColor="#7AB5C6" />
                        <stop offset={1} stopColor="#BEE3DB" />
                    </linearGradient>
                    <linearGradient
                        id="prefix__paint1_linear"
                        x1={33.933}
                        y1={25.022}
                        x2={36}
                        y2={25.022}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#fff" />
                        <stop offset={1} stopColor="#fff" stopOpacity={0} />
                    </linearGradient>
                </defs>
            </svg>
        </a>
    );
}
