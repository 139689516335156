import React from 'react';

import experiences from '../content/experiences';
import Experience from './Experience';

export default function Experiences() {
    return (
        <section
            id="experience"
            className="pt-14 bg-white text-primary-blue max-w-4xl md:mx-auto"
        >
            <div className="experiences-container p-5">
                <h2 className="pb-[76px] experiences-header font-body font-extrabold text-4xl">
                    Experience
                </h2>
                <div className="experiences-overview">
                    {experiences.map((experience) => (
                        <Experience
                            experience={experience}
                            key={experience.interval}
                        />
                    ))}
                </div>
            </div>
        </section>
    );
}
