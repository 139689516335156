/* eslint-disable react/prop-types */
import React from 'react';

export default function Tag({ className, tag }) {
    return (
        <>
            {tag.url ? (
                <a
                    href={tag.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={`inline-flex items-center mr-2 my-1 px-4 py-0.5 rounded text-xs ${className}`}
                >
                    {tag.name}
                </a>
            ) : (
                <div
                    className={`inline-flex items-center mr-2 my-1 px-4 py-0.5 rounded text-xs ${className}`}
                >
                    {tag.name}
                </div>
            )}
        </>
    );
}
