/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
import { send } from 'emailjs-com';
import { useFormik } from 'formik';
import React, { Fragment, useState, useEffect, useRef } from 'react';
import { Transition, Menu, Dialog } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import {
    ChatAlt2Icon,
    CheckCircleIcon,
    DocumentTextIcon,
    ExclamationCircleIcon,
} from '@heroicons/react/solid';

import resume from '../docs/resume.pdf';
import Notification from './Notification';
import MessageErrorNotification from './MessageErrorNotification';

function Education() {
    return (
        <div className="mobile-menu-icon bg-gradient-to-b from-[#E30813] to-[#552D90] rounded-md">
            <svg className="h-6 w-6" viewBox="0 0 24 24">
                <path
                    d="M17 13a1 1 0 011 1v3a1 1 0 01-1 1H8.5a2.5 2.5 0 010-5H17z"
                    fill="#93C5FD"
                />
                <path
                    d="M12.743 7.722a1 1 0 011.414 0l2.122 2.121a1 1 0 010 1.414l-6.01 6.01a2.5 2.5 0 11-3.536-3.536l6.01-6.01z"
                    fill="#BFDBFE"
                />
                <path
                    d="M6 7a1 1 0 011-1h3a1 1 0 011 1v8.5a2.5 2.5 0 01-5 0V7z"
                    fill="#EFF6FF"
                />
                <path d="M9.5 15.5a1 1 0 11-2 0 1 1 0 012 0z" fill="#60A5FA" />
            </svg>
        </div>
    );
}

function Experience() {
    return (
        <div className="mobile-menu-icon bg-gradient-to-b from-[#E30813] to-[#552D90] rounded-md">
            <svg className="h-6 w-6" viewBox="0 0 24 24">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M13.196 6.02a1 1 0 01.785 1.176l-2 10a1 1 0 01-1.961-.392l2-10a1 1 0 011.176-.784z"
                    fill="#C4B5FD"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M15.293 9.293a1 1 0 011.414 0l2 2a1 1 0 010 1.414l-2 2a1 1 0 01-1.414-1.414L16.586 12l-1.293-1.293a1 1 0 010-1.414zM8.707 9.293a1 1 0 010 1.414L7.414 12l1.293 1.293a1 1 0 11-1.414 1.414l-2-2a1 1 0 010-1.414l2-2a1 1 0 011.414 0z"
                    fill="#FDF4FF"
                />
            </svg>
        </div>
    );
}

function Projects() {
    return (
        <div className="mobile-menu-icon bg-gradient-to-b from-[#E30813] to-[#552D90] rounded-md">
            <svg className="h-6 w-6" viewBox="0 0 24 24">
                <path d="M6 9l6-3 6 3v6l-6 3-6-3V9z" fill="#F5F3FF" />
                <path d="M6 9l6 3v6l-6-3V9z" fill="#DDD6FE" />
                <path d="M18 9l-6 3v6l6-3V9z" fill="#C4B5FD" />
            </svg>
        </div>
    );
}

const menuLinks = [
    {
        name: 'Education',
        href: '#education',
        icon: Education,
    },
    {
        name: 'Projects',
        href: '#projects',
        icon: Projects,
    },
    {
        name: 'Experience',
        href: '#experience',
        icon: Experience,
    },
];

const validate = (values) => {
    const errors = {};

    if (!values.firstName) {
        errors.firstName = 'First Name is required.';
    }

    if (!values.lastName) {
        errors.lastName = 'Last Name is required.';
    }

    if (!values.message) {
        errors.message = 'Message is required.';
    }

    if (!values.email) {
        errors.email = 'Email is required.';
    } else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
        errors.email = 'Invalid email address';
    }

    return errors;
};

export default function MobileMenu({ open }) {
    const [isOpen, setIsOpen] = useState(false);
    const [isEmailSent, setIsEmailSent] = useState(false);
    const [isEmailError, setIsEmailError] = useState(false);

    const cancelButton = useRef(null);

    function openEmailNotification() {
        setIsEmailSent(true);
    }

    function openEmailErrorNotification() {
        setIsEmailError(true);
    }

    function setEmailErrorState(bool) {
        setIsEmailError(bool);
    }

    function closeModal() {
        setIsOpen(false);
    }

    function openModal() {
        setIsOpen(true);
    }

    function setEmailSentState(bool) {
        setIsEmailSent(bool);
    }

    const handleEmailSubmission = (values) => {
        send(
            'service_4oz67nv',
            'template_0yuet9m',
            {
                from_name: `${values.firstName}  ${values.lastName} - ${values.email}`,
                to_name: 'Jamie',
                message: values.message,
                email: values.email,
            },
            'user_PXmWqJLfquW9Rbw3Q5iI9'
        )
            .then(() => {
                closeModal();
                openEmailNotification();
            })
            .catch((err) => {
                closeModal();
                openEmailErrorNotification();
            });
    };

    const formik = useFormik({
        initialValues: {
            firstName: '',

            lastName: '',

            email: '',

            message: '',

            subject: '',
        },

        validate,

        onSubmit: (values) => handleEmailSubmission(values),
    });

    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            formik.resetForm();
            document.body.style.overflow = 'unset';
        }

        // if (isOpen) document.body.classList.add('overflow-hidden');
        // else document.body.classList.remove('overflow-hidden');
    }, [isOpen]);

    return (
        <>
            <Transition
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
            >
                <Menu.Items className="absolute z-10 w-full min-w-[320px]">
                    <div className="overflow-hidden shadow-lg ring-1 ring-black ring-opacity-5">
                        <div
                            className={`${
                                open ? 'bg-opacity-95' : 'bg-opacity-95'
                            } relative grid gap-8 bg-white p-7 lg:grid-cols-2`}
                        >
                            {menuLinks.map((item) => (
                                <div className="links" key={item.name}>
                                    <Menu.Item
                                        as="a"
                                        href={item.href}
                                        className="flex flex-col items-left hover:cursor-pointer"
                                    >
                                        <div className="link flex items-center p-1 -m-3 transition duration-500 ease-in-out rounded-lg text-gray-600 hover:bg-primary-blue hover:bg-opacity-5  focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50 ">
                                            <div className="flex items-center justify-center flex-shrink-0 w-10 h-10 text-white sm:h-12 sm:w-12">
                                                <item.icon aria-hidden="true" />
                                            </div>
                                            <div className="ml-4">
                                                <p className="text-md font-medium">
                                                    {item.name}
                                                </p>
                                            </div>
                                        </div>
                                    </Menu.Item>
                                </div>
                            ))}
                        </div>
                        <Menu.Items as="div" className="p-4 bg-white">
                            <div className="flex flex-col">
                                <Menu.Item
                                    as="a"
                                    href={resume}
                                    target="_blank"
                                    rel="noreferrer"
                                    className="inline-flex items-center justify-center mx-16 my-2 p-3 bg-white hover:bg-btn-red border-2 border-btn-red  text-center text-gray-600 hover:text-white rounded-md transition duration-500 ease-in-out"
                                >
                                    <DocumentTextIcon
                                        className="-ml-1 mr-3 h-5 w-5"
                                        aria-hidden="true"
                                    />
                                    View Résumé
                                </Menu.Item>
                                <Menu.Item
                                    as="button"
                                    onClick={openModal}
                                    className="inline-flex items-center justify-center mx-16 my-2 p-3 bg-white hover:bg-btn-red border-2 border-btn-red  text-center hover:text-white rounded-md transition duration-500 ease-in-out"
                                >
                                    <ChatAlt2Icon
                                        className="-ml-1 mr-3 h-5 w-5"
                                        aria-hidden="true"
                                    />
                                    Get In Touch
                                </Menu.Item>
                                <div className="mt-4 mb-2 flex items-center justify-center">
                                    <Menu.Item
                                        as="a"
                                        href="https://github.com/jsam07"
                                        target="_blank"
                                        aria-label="GitHub"
                                        rel="noopener noreferrer"
                                        className="ml-6"
                                    >
                                        <span className="sr-only">GitHub</span>
                                        <svg
                                            viewBox="0 0 24 24"
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="w-6 h-6 text-project-link-pale transition duration-300 ease-in-out hover:-translate-y-1 hover:scale-110 hover:text-btn-red"
                                            fill="currentColor"
                                        >
                                            <path d="M12.026 2C7.13295 1.99937 2.96183 5.54799 2.17842 10.3779C1.395 15.2079 4.23061 19.893 8.87302 21.439C9.37302 21.529 9.55202 21.222 9.55202 20.958C9.55202 20.721 9.54402 20.093 9.54102 19.258C6.76602 19.858 6.18002 17.92 6.18002 17.92C5.99733 17.317 5.60459 16.7993 5.07302 16.461C4.17302 15.842 5.14202 15.856 5.14202 15.856C5.78269 15.9438 6.34657 16.3235 6.66902 16.884C6.94195 17.3803 7.40177 17.747 7.94632 17.9026C8.49087 18.0583 9.07503 17.99 9.56902 17.713C9.61544 17.207 9.84055 16.7341 10.204 16.379C7.99002 16.128 5.66202 15.272 5.66202 11.449C5.64973 10.4602 6.01691 9.5043 6.68802 8.778C6.38437 7.91731 6.42013 6.97325 6.78802 6.138C6.78802 6.138 7.62502 5.869 9.53002 7.159C11.1639 6.71101 12.8882 6.71101 14.522 7.159C16.428 5.868 17.264 6.138 17.264 6.138C17.6336 6.97286 17.6694 7.91757 17.364 8.778C18.0376 9.50423 18.4045 10.4626 18.388 11.453C18.388 15.286 16.058 16.128 13.836 16.375C14.3153 16.8651 14.5612 17.5373 14.511 18.221C14.511 19.555 14.499 20.631 14.499 20.958C14.499 21.225 14.677 21.535 15.186 21.437C19.8265 19.8884 22.6591 15.203 21.874 10.3743C21.089 5.54565 16.9181 1.99888 12.026 2Z" />
                                        </svg>
                                    </Menu.Item>
                                    <Menu.Item
                                        as="a"
                                        href="https://www.linkedin.com/in/jsam07/"
                                        target="_blank"
                                        aria-label="LinkedIn"
                                        rel="noopener noreferrer"
                                        className="ml-6"
                                    >
                                        <span className="sr-only">
                                            LinkedIn
                                        </span>
                                        <svg
                                            viewBox="0 0 24 24"
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="w-5 h-5 text-project-link-pale transition duration-300 ease-in-out hover:-translate-y-1 hover:scale-110 hover:text-btn-red"
                                            fill="currentColor"
                                        >
                                            <path d="M20.447 20.452h-3.554v-5.569c0-1.328-.027-3.037-1.852-3.037-1.853 0-2.136 1.445-2.136 2.939v5.667H9.351V9h3.414v1.561h.046c.477-.9 1.637-1.85 3.37-1.85 3.601 0 4.267 2.37 4.267 5.455v6.286zM5.337 7.433c-1.144 0-2.063-.926-2.063-2.065 0-1.138.92-2.063 2.063-2.063 1.14 0 2.064.925 2.064 2.063 0 1.139-.925 2.065-2.064 2.065zm1.782 13.019H3.555V9h3.564v11.452zM22.225 0H1.771C.792 0 0 .774 0 1.729v20.542C0 23.227.792 24 1.771 24h20.451C23.2 24 24 23.227 24 22.271V1.729C24 .774 23.2 0 22.222 0h.003z" />
                                        </svg>
                                    </Menu.Item>
                                </div>
                            </div>
                        </Menu.Items>
                    </div>
                </Menu.Items>
            </Transition>
            <Transition.Root show={isOpen} as={Fragment}>
                <Dialog
                    as="div"
                    auto-reopen="true"
                    className="fixed z-50 inset-0 overflow-y-auto"
                    onClose={closeModal}
                >
                    <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                        </Transition.Child>

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span
                            className="hidden sm:inline-block sm:align-middle sm:h-screen"
                            aria-hidden="true"
                        >
                            &#8203;
                        </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                                <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                                    <button
                                        type="button"
                                        className="bg-white rounded-md text-gray-400 hover:text-gray-500"
                                        onClick={closeModal}
                                    >
                                        <span className="sr-only">Close</span>
                                        <XIcon
                                            className="h-6 w-6"
                                            aria-hidden="true"
                                        />
                                    </button>
                                </div>
                                <div className="sm:flex sm:items-start">
                                    <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-gradient-to-r from-[#E70183] to-[#552D90] sm:mx-0 sm:h-10 sm:w-10">
                                        <ChatAlt2Icon
                                            className="h-6 w-6 text-white"
                                            aria-hidden="true"
                                        />
                                    </div>
                                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                        <Dialog.Title
                                            as="h3"
                                            className="text-2xl font-bold leading-6 text-gray-900 sm:pt-1"
                                        >
                                            Contact Me
                                        </Dialog.Title>
                                        <div className="mt-2">
                                            <p className="text-sm text-gray-500">
                                                If you&apos;re interested in
                                                working together or have any
                                                feedback, I&apos;d love to hear
                                                from you!
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <form onSubmit={formik.handleSubmit}>
                                    <div className="px-4 py-5 bg-white sm:p-6">
                                        <div className="grid grid-cols-6 gap-6">
                                            <div className="col-span-6 sm:col-span-3">
                                                <label
                                                    htmlFor="firstName"
                                                    className="block text-sm font-medium text-gray-700"
                                                >
                                                    First Name
                                                    <div className="mt-1 relative rounded-md shadow-sm">
                                                        <input
                                                            type="text"
                                                            name="firstName"
                                                            id="firstName"
                                                            autoComplete="given-name"
                                                            // placeholder="Jane"
                                                            onChange={
                                                                formik.handleChange
                                                            }
                                                            value={
                                                                formik.values
                                                                    .firstName
                                                            }
                                                            className={`${
                                                                formik.errors
                                                                    .firstName
                                                                    ? 'border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500'
                                                                    : formik
                                                                          .values
                                                                          .firstName
                                                                    ? 'border-green-300 text-green-900 placeholder-green-300 focus:outline-none focus:ring-green-500 focus:border-green-500'
                                                                    : 'focus:ring-project-link focus:border-project-link'
                                                            } placeholder-gray-500 placeholder-opacity-50  block w-full shadow-sm sm:text-sm border-gray-300 rounded-md`}
                                                        />
                                                        {formik.errors
                                                            .firstName ? (
                                                            <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                                                <ExclamationCircleIcon
                                                                    className="h-5 w-5 text-red-500"
                                                                    aria-hidden="true"
                                                                />
                                                            </div>
                                                        ) : formik.values
                                                              .firstName ? (
                                                            <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                                                <CheckCircleIcon
                                                                    className="h-5 w-5 text-green-500"
                                                                    aria-hidden="true"
                                                                />
                                                            </div>
                                                        ) : (
                                                            ''
                                                        )}
                                                    </div>
                                                </label>
                                                {formik.errors.firstName && (
                                                    <p
                                                        className="mt-2 text-sm text-red-600"
                                                        id="firstName-error"
                                                    >
                                                        {
                                                            formik.errors
                                                                .firstName
                                                        }
                                                    </p>
                                                )}
                                            </div>

                                            <div className="col-span-6 sm:col-span-3">
                                                <label
                                                    htmlFor="lastName"
                                                    className="block text-sm font-medium text-gray-700"
                                                >
                                                    Last Name
                                                    <div className="mt-1 relative rounded-md shadow-sm">
                                                        <input
                                                            type="text"
                                                            name="lastName"
                                                            id="lastName"
                                                            autoComplete="family-name"
                                                            // placeholder="Doe"
                                                            onChange={
                                                                formik.handleChange
                                                            }
                                                            value={
                                                                formik.values
                                                                    .lastName
                                                            }
                                                            className={`${
                                                                formik.errors
                                                                    .lastName
                                                                    ? 'border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500'
                                                                    : formik
                                                                          .values
                                                                          .lastName
                                                                    ? 'border-green-300 text-green-900 placeholder-green-300 focus:outline-none focus:ring-green-500 focus:border-green-500'
                                                                    : 'focus:ring-project-link focus:border-project-link'
                                                            } placeholder-gray-500 placeholder-opacity-50  block w-full shadow-sm sm:text-sm border-gray-300 rounded-md`}
                                                        />
                                                        {formik.errors
                                                            .lastName ? (
                                                            <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                                                <ExclamationCircleIcon
                                                                    className="h-5 w-5 text-red-500"
                                                                    aria-hidden="true"
                                                                />
                                                            </div>
                                                        ) : formik.values
                                                              .lastName ? (
                                                            <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                                                <CheckCircleIcon
                                                                    className="h-5 w-5 text-green-500"
                                                                    aria-hidden="true"
                                                                />
                                                            </div>
                                                        ) : (
                                                            ''
                                                        )}
                                                    </div>
                                                </label>
                                                {formik.errors.lastName && (
                                                    <p
                                                        className="mt-2 text-sm text-red-600"
                                                        id="lastName-error"
                                                    >
                                                        {formik.errors.lastName}
                                                    </p>
                                                )}
                                            </div>

                                            <div className="col-span-6 sm:col-span-6">
                                                <label
                                                    htmlFor="email"
                                                    className="block text-sm font-medium text-gray-700"
                                                >
                                                    Email Address
                                                    <div className="mt-1 relative rounded-md shadow-sm">
                                                        <input
                                                            type="email"
                                                            name="email"
                                                            id="email"
                                                            autoComplete="email"
                                                            // placeholder="test@gmail.com"
                                                            onChange={
                                                                formik.handleChange
                                                            }
                                                            value={
                                                                formik.values
                                                                    .email
                                                            }
                                                            className={`${
                                                                formik.errors
                                                                    .email
                                                                    ? 'border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500'
                                                                    : formik
                                                                          .values
                                                                          .email
                                                                    ? 'border-green-300 text-green-900 placeholder-green-300 focus:outline-none focus:ring-green-500 focus:border-green-500'
                                                                    : 'focus:ring-project-link focus:border-project-link'
                                                            } placeholder-gray-500 placeholder-opacity-50  block w-full shadow-sm sm:text-sm border-gray-300 rounded-md`}
                                                        />
                                                        {formik.errors.email ? (
                                                            <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                                                <ExclamationCircleIcon
                                                                    className="h-5 w-5 text-red-500"
                                                                    aria-hidden="true"
                                                                />
                                                            </div>
                                                        ) : formik.values
                                                              .email ? (
                                                            <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                                                <CheckCircleIcon
                                                                    className="h-5 w-5 text-green-500"
                                                                    aria-hidden="true"
                                                                />
                                                            </div>
                                                        ) : (
                                                            ''
                                                        )}
                                                    </div>
                                                </label>
                                                {formik.errors.email && (
                                                    <p
                                                        className="mt-2 text-sm text-red-600"
                                                        id="lastName-error"
                                                    >
                                                        {formik.errors.email}
                                                    </p>
                                                )}
                                            </div>

                                            <div className="col-span-6 sm:col-span-6">
                                                <label
                                                    htmlFor="subject"
                                                    className="block text-sm font-medium text-gray-700"
                                                >
                                                    Subject
                                                    <div className="mt-1 relative rounded-md shadow-sm">
                                                        <input
                                                            type="text"
                                                            name="subject"
                                                            id="subject"
                                                            autoComplete="family-name"
                                                            // placeholder="Subject"
                                                            onChange={
                                                                formik.handleChange
                                                            }
                                                            className="block w-full mt-1 placeholder-gray-500 placeholder-opacity-5 focus:ring-project-link focus:border-project-link shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                        />
                                                    </div>
                                                </label>
                                            </div>
                                            <div className="col-span-6 sm:col-span-6">
                                                <label
                                                    htmlFor="message"
                                                    className="block text-sm font-medium text-gray-700"
                                                >
                                                    Message
                                                    <div className="mt-1 relative rounded-md shadow-sm">
                                                        <div className="mt-1">
                                                            <textarea
                                                                id="message"
                                                                name="message"
                                                                rows={8}
                                                                // defaultValue=""
                                                                onChange={
                                                                    formik.handleChange
                                                                }
                                                                value={
                                                                    formik
                                                                        .values
                                                                        .message
                                                                }
                                                                // placeholder="Type message here"
                                                                className={`${
                                                                    formik
                                                                        .errors
                                                                        .message
                                                                        ? 'border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500'
                                                                        : formik
                                                                              .values
                                                                              .message
                                                                        ? 'border-green-300 text-green-900 placeholder-green-300 focus:outline-none focus:ring-green-500 focus:border-green-500'
                                                                        : 'focus:ring-project-link focus:border-project-link'
                                                                } placeholder-gray-500 placeholder-opacity-50  block w-full shadow-sm sm:text-sm border-gray-300 rounded-md`}
                                                            />
                                                        </div>
                                                        {formik.errors
                                                            .message ? (
                                                            <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                                                <ExclamationCircleIcon
                                                                    className="h-5 w-5 text-red-500"
                                                                    aria-hidden="true"
                                                                />
                                                            </div>
                                                        ) : formik.values
                                                              .message ? (
                                                            <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                                                <CheckCircleIcon
                                                                    className="h-5 w-5 text-green-500"
                                                                    aria-hidden="true"
                                                                />
                                                            </div>
                                                        ) : (
                                                            ''
                                                        )}
                                                    </div>
                                                </label>
                                                {formik.errors.message && (
                                                    <p
                                                        className="mt-2 text-sm text-red-600"
                                                        id="lastName-error"
                                                    >
                                                        {formik.errors.message}
                                                    </p>
                                                )}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                                        <button
                                            type="submit"
                                            className="w-full inline-flex items-center justify-center rounded-md  shadow-sm px-4 py-2 bg-gradient-to-r from-[#E70183] to-[#552D90] text-base font-medium text-white hover:bg-red-700 sm:ml-3 sm:w-auto sm:text-sm"
                                        >
                                            Send Message
                                        </button>
                                        <button
                                            type="button"
                                            className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
                                            onClick={() => {
                                                formik.resetForm();
                                                closeModal();
                                            }}
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition.Root>
            {isEmailSent && <Notification setState={setEmailSentState} />}
            {isEmailError && (
                <MessageErrorNotification setState={setEmailErrorState} />
            )}
        </>
    );
}
