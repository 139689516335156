import React from 'react';

export default function NavBarLinks() {
    return (
        <div className="desktop-links" id="desktop-links">
            <ul className="nav-menu-items">
                <li>
                    <a href="#hero">
                        <span>Home</span>
                    </a>
                </li>
                <li>
                    <a href="#education">
                        <span>Education</span>
                    </a>
                </li>
                <li>
                    <a href="#experience">
                        <span>Experience</span>
                    </a>
                </li>
                <li>
                    <a href="#projects">
                        <span>Projects</span>
                    </a>
                </li>
            </ul>
        </div>
    );
}
