import React from 'react';
import Tag from '../components/Tag';

/* eslint-disable react/display-name */
const tags = {
    featured: () => {
        const tag = { name: 'Featured' };
        return <Tag className="bg-[#00850D] text-[#fff]" tag={tag} />;
    },
    collab: () => {
        const tag = { name: 'Collaborative' };
        return <Tag className="bg-[#2929FF] text-[#fff]" tag={tag} />;
    },
    doc: () => {
        const tag = { name: 'Documentation' };
        return <Tag className="bg-[#D91212] text-[#fff]" tag={tag} />;
    },
    react: () => {
        const tag = { name: 'React', url: 'https://reactjs.org/' };
        return <Tag className="bg-[#61DAFB] text-[#000]" tag={tag} />;
    },
    vue: () => {
        const tag = { name: 'Vue.js', url: 'https://vuejs.org/' };
        return <Tag className="bg-[#42b883] text-[#fff]" tag={tag} />;
    },
    gatsby: () => {
        const tag = { name: 'Gatsby', url: 'https://www.gatsbyjs.com/' };
        return <Tag className="bg-[#663399] text-[#fff]" tag={tag} />;
    },
    typescript: () => {
        const tag = {
            name: 'TypeScript',
            url: 'https://www.typescriptlang.org/',
        };
        return <Tag className="bg-[#3178C6] text-[#fff]" tag={tag} />;
    },
    javascript: () => {
        const tag = {
            name: 'JavaScript',
            url: 'https://www.javascript.com/',
        };
        return <Tag className="bg-[#FCDC00] text-[#000]" tag={tag} />;
    },
    cypress: () => {
        const tag = {
            name: 'Cypress',
            url: 'https://www.cypress.io/',
        };
        return <Tag className="bg-[#23262E] text-[#fff]" tag={tag} />;
    },
    next: () => {
        const tag = {
            name: 'Next.js',
            url: 'https://nextjs.org/',
        };
        return <Tag className="bg-[#000] text-[#fff]" tag={tag} />;
    },
    jest: () => {
        const tag = {
            name: 'Jest',
            url: 'https://jestjs.io/',
        };
        return <Tag className="bg-[#944058] text-[#fff]" tag={tag} />;
    },
    unity: () => {
        const tag = {
            name: 'Unity',
            url: 'https://unity.com/',
        };
        return <Tag className="bg-[#000] text-[#fff]" tag={tag} />;
    },

    webgl2: () => {
        const tag = {
            name: 'WebGL 2.0',
            url: 'https://www.khronos.org/registry/webgl/specs/latest/2.0/',
        };
        return <Tag className="bg-[#9a0000] text-[#f8f9fa]" tag={tag} />;
    },

    csharp: () => {
        const tag = {
            name: 'C#',
            url: 'https://en.wikipedia.org/wiki/C_Sharp_(programming_language)',
        };
        return <Tag className="bg-[#009404] text-[#fff]" tag={tag} />;
    },
    markdown: () => {
        const tag = {
            name: 'Markdown',
            url: 'https://en.wikipedia.org/wiki/Markdown',
        };
        return <Tag className="bg-[#000] text-[#fff]" tag={tag} />;
    },
    express: () => {
        const tag = {
            name: 'Express.js',
            url: 'https://expressjs.com/',
        };
        return <Tag className="bg-[#000] text-[#fff]" tag={tag} />;
    },
    jquery: () => {
        const tag = {
            name: 'jQuery',
            url: 'https://jquery.com/',
        };
        return <Tag className="bg-[#0769ad] text-[#fff]" tag={tag} />;
    },
    stripe: () => {
        const tag = {
            name: 'Stripe',
            url: 'https://stripe.com/en-ca',
        };
        return <Tag className="bg-[#6772e5] text-[#fff]" tag={tag} />;
    },
    css: () => {
        const tag = {
            name: 'CSS',
            url: 'https://en.wikipedia.org/wiki/CSS',
        };
        return <Tag className="bg-[#264de4] text-[#fff]" tag={tag} />;
    },
    passport: () => {
        const tag = {
            name: 'Passport.js',
            url: 'http://www.passportjs.org/',
        };
        return <Tag className="bg-[#cdf502] text-[#000]" tag={tag} />;
    },
    prisma: () => {
        const tag = {
            name: 'Prisma ORM',
            url: 'https://www.prisma.io/',
        };
        return <Tag className="bg-[#0c3349] text-[#fff]" tag={tag} />;
    },
    tailwind: () => {
        const tag = {
            name: 'Tailwind CSS',
            url: 'https://tailwindcss.com/',
        };
        return <Tag className="bg-[#15b8c5] text-[#fff]" tag={tag} />;
    },
    node: () => {
        const tag = {
            name: 'Node.js',
            url: 'https://nodejs.org/en/',
        };
        return <Tag className="bg-[#02c27c] text-[#252c3b]" tag={tag} />;
    },
    aws: () => {
        const tag = {
            name: 'AWS',
            url: 'https://aws.amazon.com/',
        };
        return <Tag className="bg-[#ec912d] text-[#fff]" tag={tag} />;
    },
    s3: () => {
        const tag = {
            name: 'AWS S3',
            url: 'https://aws.amazon.com/s3',
        };
        return <Tag className="bg-[#da5041] text-[#fff]" tag={tag} />;
    },
    amplify: () => {
        const tag = {
            name: 'AWS Amplify',
            url: 'https://aws.amazon.com/amplify',
        };
        return <Tag className="bg-[#f4a731] text-[#222e3c]" tag={tag} />;
    },
    mongo: () => {
        const tag = {
            name: 'MongoDB',
            url: 'https://www.mongodb.com/',
        };
        return <Tag className="bg-[#13aa52] text-[#fff]" tag={tag} />;
    },
    docker: () => {
        const tag = {
            name: 'Docker',
            url: 'https://www.docker.com/',
        };
        return <Tag className="bg-[#2496ed] text-[#000]" tag={tag} />;
    },
    python: () => {
        const tag = {
            name: 'Python',
            url: 'https://www.python.org/',
        };
        return <Tag className="bg-[#3673a5] text-[#fff]" tag={tag} />;
    },
    electron: () => {
        const tag = {
            name: 'Electron',
            url: 'https://www.electronjs.org/',
        };
        return <Tag className="bg-[#2b2e3b] text-[#9feaf9]" tag={tag} />;
    },
};

export default tags;
