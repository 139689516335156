/* eslint-disable react/prop-types */
import React, { useEffect, useRef } from 'react';
import { useInView } from 'react-intersection-observer';
import { Disclosure, Transition } from '@headlessui/react';
import { ArrowCircleDownIcon } from '@heroicons/react/solid';

export default function Experience({ experience }) {
    const buttonRef = useRef(null);
    const { ref, inView } = useInView({ threshold: 0, triggerOnce: true });
    const Logo = experience.logo;

    useEffect(() => {
        if (experience.company === 'VentureXPerience (VXP)') {
            buttonRef.current.click();
        }
    }, []);
    return (
        <>
            <div
                ref={ref}
                className={`mb-6 bg-white rounded fade-in-section  ${
                    inView ? 'is-visible' : ''
                }`}
            >
                <div className="">
                    <Disclosure>
                        {({ open }) => (
                            <>
                                <div className="experience bg-secondary-gray rounded-lg">
                                    <div className="education-section p-4 flex flex-row bg-secondary-gray rounded">
                                        <div className="flex-none school-logo mr-3 w-10 h-10">
                                            <Logo className="w-10 h-10" />
                                        </div>
                                        <div className="experience-summary">
                                            <div className="experience-role font-bold leading-relaxed">
                                                {experience.role}
                                            </div>
                                            <div className="experience-details leading-relaxed">
                                                {experience.department && (
                                                    <div className="experience-department">
                                                        {experience.department}
                                                    </div>
                                                )}
                                                <div className="experience-company">
                                                    {experience.company}
                                                </div>
                                                <div className="experience-interval">
                                                    {experience.interval}
                                                </div>
                                                <div className="experience-location">
                                                    {experience.location}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <Transition
                                        show={open}
                                        enter="transition duration-500 ease-out"
                                        enterFrom="transform scale-95 opacity-0"
                                        enterTo="transform scale-100 opacity-100"
                                        leave="transition duration-75 ease-out"
                                        leaveFrom="transform scale-100 opacity-100"
                                        leaveTo="transform scale-95 opacity-0"
                                    >
                                        <Disclosure.Panel className="text-base text-gray-500">
                                            <ul className="list-disc ml-6 px-4 text-left">
                                                {experience.duties.map(
                                                    (duty) => (
                                                        <li
                                                            key={duty}
                                                            className="mb-1.5 pl-4"
                                                        >
                                                            {duty}
                                                        </li>
                                                    )
                                                )}
                                            </ul>
                                        </Disclosure.Panel>
                                    </Transition>
                                    <div className="expand-button flex justify-end">
                                        <Disclosure.Button
                                            ref={buttonRef}
                                            className="hover:cursor-pointer"
                                            aria-label="View Duties"
                                        >
                                            <ArrowCircleDownIcon
                                                className={`
                                        m-2 w-6 h-6 text-project-link-pale transition duration-300 ease-in-out hover:-translate-y-1 hover:scale-110 hover:text-project-link
                                        ${
                                            open
                                                ? 'transform rotate-180'
                                                : 'animate-bounce'
                                        }`}
                                            />
                                        </Disclosure.Button>
                                    </div>
                                </div>
                            </>
                        )}
                    </Disclosure>
                </div>
            </div>
        </>
    );
}
