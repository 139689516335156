/* eslint-disable react/prop-types */
import React from 'react';

export default function BCITLogo({ className }) {
    return (
        <a
            href="https://www.bcit.ca/"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="BCIT Logo"
        >
            <svg
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className={className}
            >
                <path d="M40 0H0V40H40V0Z" fill="#003E6B" />
                <path
                    d="M31.3063 26.6301V14.9287H28.4523V12.865H36.3996V14.9287H33.5675V26.6301"
                    fill="white"
                />
                <path
                    d="M26.5862 12.865H24.3029V26.652H26.5862V12.865Z"
                    fill="white"
                />
                <path
                    d="M22.3711 22.1515C21.9539 25.225 20.6586 26.8057 18.1559 26.8057C14.8628 26.8057 13.7871 24.1273 13.7871 19.6926C13.7871 15.719 14.9287 12.6893 18.1998 12.6893C20.8562 12.6893 21.91 14.3359 22.2393 17.146L20.1318 17.3436C19.8464 15.6092 19.2536 14.731 18.2437 14.731C16.8387 14.731 16.1142 16.202 16.1142 19.6926C16.1142 23.5126 16.7509 24.7859 18.2218 24.7859C19.3195 24.7859 19.9781 23.82 20.2635 21.9319"
                    fill="white"
                />
                <path
                    d="M4.06142 26.6301V12.843H7.46427C10.6037 12.843 12.0526 13.8749 12.0526 16.4215C12.0526 17.8705 11.416 18.8145 10.2744 19.3414V19.3853C11.8331 19.9341 12.3819 21.1196 12.3819 22.6784C12.3819 25.8617 10.3183 26.6301 7.6399 26.6301H4.06142ZM7.55209 18.573C8.71564 18.573 9.76943 18.1559 9.76943 16.641C9.76943 15.1701 8.67174 14.9067 7.55209 14.9067H6.27876V18.5949H7.55209V18.573ZM7.77163 24.6103C9.37426 24.6103 10.0548 24.0615 10.0548 22.5466C10.0548 21.0538 9.28644 20.4391 7.79358 20.4391H6.27876V24.6103H7.77163Z"
                    fill="white"
                />
            </svg>
        </a>
    );
}
