/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import Project from './Project';
import projects from '../content/projects';

export default function Projects() {
    return (
        <section
            id="projects"
            className="pt-14 bg-white text-primary-blue max-w-4xl md:mx-auto"
        >
            <div className="projects-container p-5">
                <h2 className="pb-[76px] projects-header font-body font-extrabold text-4xl">
                    Projects
                </h2>
                <div className="project-overview flex flex-col">
                    {projects.map((project) => (
                        <Project project={project} key={project.title} />
                    ))}
                </div>
            </div>
        </section>
    );
}
