/* eslint-disable react/prop-types */
import React from 'react';

export default function UBCLogo({ className }) {
    return (
        <a
            href="https://www.ubc.ca/"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="UBC Logo"
        >
            <svg
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className={className}
            >
                <path d="M40 0H0V40H40V0Z" fill="#0C2344" />
                <path
                    d="M20.4132 16.7966L21.5235 24.8512L25.1726 18.7535L23.6243 25.4605L27.5582 21.6967L25.5097 26.6657L28.7828 24.2229C27.2593 28.3691 25.9315 30.6936 20.4132 32.5831C14.8949 30.6936 13.5671 28.3691 12.0436 24.223L15.3167 26.6656L13.2682 21.6966L17.2021 25.4604L15.6538 18.7534L19.3029 24.8512L20.4132 16.7966Z"
                    fill="white"
                />
                <path
                    d="M26.8673 14.098C24.9583 14.098 23.7257 12.9061 23.7257 11.0575C23.7257 9.2308 25.0035 8.00237 26.9049 8.00237C27.5361 7.98456 28.1611 8.13096 28.7186 8.42721L28.7638 8.45226L28.5567 9.65318L28.4656 9.59239C28.0245 9.27428 27.4956 9.10077 26.9518 9.09577C25.8178 9.09577 25.1398 9.82666 25.1398 11.0501C25.1398 12.2513 25.8409 12.9968 26.9707 12.9968C27.5424 12.9859 28.0999 12.816 28.5806 12.5062L28.6711 12.452L28.8665 13.5722L28.823 13.5975C28.2248 13.9292 27.5513 14.1015 26.8673 14.098Z"
                    fill="white"
                />
                <path
                    d="M19.8687 10.47H20.3724C21.047 10.47 21.4202 10.2169 21.4202 9.75815C21.4202 9.30693 21.0948 9.08694 20.4274 9.08694H19.8687V10.47ZM19.8687 13.0135H20.4653C21.0044 13.0135 21.6453 12.8758 21.6453 12.2183C21.6453 11.5407 20.9937 11.3966 20.4455 11.3966H19.8687V13.0135ZM20.4653 13.9984H18.5293V8.10083H20.4941C21.9304 8.10083 22.7217 8.65846 22.7217 9.66712C22.7237 9.92363 22.6478 10.1747 22.504 10.3871C22.3602 10.5995 22.1553 10.7633 21.9164 10.8568C22.6249 11.1069 22.9848 11.5748 22.9848 12.2513C22.9848 13.3786 22.0907 13.9984 20.4653 13.9984"
                    fill="white"
                />
                <path
                    d="M14.6323 14.098C12.9602 14.098 11.9618 13.2033 11.9618 11.6999V8.10083H13.348V11.6432C13.348 12.5166 13.8073 12.9969 14.6431 12.9969C15.5001 12.9969 15.9545 12.5368 15.9545 11.6681V8.10083H17.35V11.676C17.35 13.1703 16.3087 14.098 14.6323 14.098Z"
                    fill="white"
                />
                <path
                    d="M10 6.25V21.6523C10 28.1254 13.522 32.812 20.4133 34.65C27.3045 32.812 30.8267 28.1253 30.8267 21.6523V6.25H10ZM20.4133 33.8323C13.8984 31.9867 10.7889 27.6854 10.7889 21.5536V7.03889H30.0378V21.5536C30.0378 27.6854 26.9281 31.9867 20.4133 33.8323Z"
                    fill="white"
                />
                <path
                    d="M20.4135 16.0871C21.8654 16.0871 22.0618 16.6761 23.2313 16.6689C24.3965 16.6762 24.5962 16.0871 26.0482 16.0871C27.5002 16.0871 27.6999 16.6761 28.8661 16.6689V15.3167C27.6999 15.3245 27.5001 14.7427 26.0482 14.7427C24.5962 14.7427 24.3965 15.3245 23.2313 15.3167C22.0618 15.3245 21.8654 14.7427 20.4135 14.7427C18.9616 14.7427 18.765 15.3245 17.5956 15.3167C16.4304 15.3245 16.2306 14.7427 14.7787 14.7427C13.3267 14.7427 13.1261 15.3245 11.9608 15.3167V16.6689C13.1261 16.6762 13.3265 16.0871 14.7787 16.0871C16.2308 16.0871 16.4304 16.6761 17.5956 16.6689C18.765 16.6762 18.9613 16.0871 20.4135 16.0871Z"
                    fill="white"
                />
                <path
                    d="M21.3337 18.5307C22.0342 18.6983 22.3675 19.0183 23.2315 19.0129C24.3968 19.0202 24.5964 18.4311 26.0484 18.4311C27.5004 18.4311 27.7002 19.0201 28.8663 19.0129V17.6606C27.7002 17.6685 27.5004 17.0867 26.0484 17.0867C24.5965 17.0867 24.3967 17.6685 23.2315 17.6606C22.2927 17.667 21.9805 17.2934 21.1428 17.1458L21.3337 18.5307Z"
                    fill="white"
                />
                <path
                    d="M17.5957 19.0129C18.4594 19.0183 18.7927 18.6985 19.4928 18.5309L19.6837 17.1459C18.8464 17.2936 18.5342 17.667 17.5957 17.6606C16.4304 17.6685 16.2306 17.0867 14.7787 17.0867C13.3267 17.0867 13.1261 17.6685 11.9608 17.6606V19.0129C13.1261 19.0202 13.3265 18.4311 14.7787 18.4311C16.2308 18.4311 16.4304 19.0202 17.5957 19.0129Z"
                    fill="white"
                />
                <path
                    d="M21.6705 20.9734C22.0454 21.1391 22.4385 21.2605 22.8416 21.3349L23.6532 19.9787C23.5135 19.9975 23.3726 20.0062 23.2317 20.0048C22.4283 20.0102 22.0841 19.7373 21.4765 19.5659L21.6705 20.9734Z"
                    fill="white"
                />
                <path
                    d="M25.3875 20.8236C25.6062 20.7901 25.8272 20.7739 26.0484 20.7752C27.5003 20.7752 27.7001 21.3642 28.8663 21.357V20.0047C27.7001 20.0126 27.5003 19.4308 26.0484 19.4308C25.9256 19.4308 25.8125 19.4353 25.7063 19.4429L25.3875 20.8236Z"
                    fill="white"
                />
                <path
                    d="M15.4393 20.8235L15.1206 19.4428C15.0146 19.4352 14.9017 19.4308 14.7791 19.4308C13.327 19.4308 13.1265 20.0126 11.9613 20.0047V21.357C13.1265 21.3643 13.327 20.7752 14.7791 20.7752C15.0001 20.7739 15.2208 20.7901 15.4393 20.8235Z"
                    fill="white"
                />
                <path
                    d="M17.9852 21.3349C18.3884 21.2606 18.7814 21.1393 19.1562 20.9735L19.3502 19.5659C18.743 19.7373 18.3989 20.01 17.5957 20.0046C17.4546 20.0061 17.3135 19.9973 17.1736 19.9785L17.9852 21.3349Z"
                    fill="white"
                />
            </svg>
        </a>
    );
}
