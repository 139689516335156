/* eslint-disable react/display-name */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import UBCLogo from '../components/UBCLogo';
import VXPLogo from '../components/VXPLogo';

const experiences = [
    {
        logo: (props) => <VXPLogo {...props} />,
        company: 'VentureXPerience (VXP)',
        role: 'Web Developer | Part-Time',
        interval: 'Jan 2021 - Sep 2021',
        location: 'Vancouver, BC',
        duties: [
            "Collaborated cross-functionally with SFU students to host Western Canada's largest design-focused hackathon.",
            "Worked closely with the design and executive teams to implement, deploy, and maintain, the hackathon's marketing website using JavaScript (ES6), React, Next.js, and Tailwind CSS. Along with various social media platforms, the website facilitated the registration and ultimate participation of over 300 attendees worldwide.",
            'Designed an effective CI/CD pipeline by leveraging GitHub Actions & AWS Amplify, which helped streamline the development lifecycle, leading to faster and higher-quality releases.',
        ],
    },
    {
        logo: (props) => <UBCLogo {...props} />,
        company: 'University of British Columbia',
        role: 'Undergraduate Teaching Lab Assistant',
        department: 'Department of Chemical & Biological Engineering',
        interval: 'Apr 2017 – Aug 2020 • 3 yrs 5 mos',
        location: 'Vancouver, BC',
        duties: [
            'Architected and implemented a responsive front-end LabVIEW application which allowed chemical engineering undergraduates to monitor dissolved oxygen (dO2) changes in real-time.',
            'Directed the development and improvement of various internal software tools, which enhanced the laboratory experience of over 900 engineering undergraduates.',
            'Spearheaded the design and integration of a new undergraduate lab that exposed students to the environmental impact of chemical dyes.',
            'Created an automated marking system for instructors and teaching assistants, using Excel and Python, which reduced lab report marking time by over 80%.',
            'Collaborated with two senior instructors to develop lab protocols, resulting in improved safety practices for undergraduate students and teaching assistants.',
        ],
    },
];

export default experiences;
