import React from 'react';

import Hero from './Hero';
import Projects from './Projects';
import Education from './Education';
import Experiences from './Experiences';

export default function MainContent() {
    return (
        <article>
            <Hero />
            <Education />
            <Experiences />
            <Projects />
        </article>
    );
}
